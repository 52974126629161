import { ApolloClient, gql } from '@apollo/client'

export const getViewerRegionQuery = gql`
  query getViewerRegion {
    viewer {
      region
    }
  }
`

export const getRegion = async (client: ApolloClient<object>) => {
  const { data } = await client.query({ query: getViewerRegionQuery, fetchPolicy: 'network-only' })

  return getRegionWithOverrides(data?.viewer?.region)
}

export const getRegionWithOverrides = (region?: string | null) => {
  const defaultRegion = 'US'
  const mockRegion = process.env.NEXT_PUBLIC_MOCK_REGION?.toUpperCase()

  return (process.env.NEXT_PUBLIC_ANGEL_ENV === 'development' && mockRegion) || region?.toUpperCase() || defaultRegion
}
