import React, { useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { PlayIcon } from '@/atoms/Icons/PlayIcon'
import { AsDiv, TitleXS } from '@/atoms/Text'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { Modal } from '@/molecules/Modal'
import { FeaturedProjectContentCollection, Maybe } from '@/types/codegen-contentful'
import { GuildRolesQuery } from '@/types/codegen-federation'
import { buildAnalyticsLink } from '@/utils/AnalyticsLinkBuilder'
import { useSafeTrack } from '@/utils/analytics'
import { useTranslate } from '@/utils/translate/translate-client'

interface FeaturedProjectWatchButtonGroupProps {
  campaign?: Maybe<string>
  contentCollection?: Maybe<FeaturedProjectContentCollection>
  href?: Maybe<string>
  guildMemberHref?: Maybe<string>
  data?: GuildRolesQuery
}

export const FeaturedProjectWatchButtonGroup: React.FC<FeaturedProjectWatchButtonGroupProps> = ({
  campaign,
  contentCollection,
  href,
}) => {
  const { t } = useTranslate('common')
  const track = useSafeTrack()
  const { asPath } = useRouter()
  const [showModal, setShowModal] = useState(false)
  const analyticsLink = buildAnalyticsLink({
    href: href as string,
    path: asPath,
    campaign: campaign as string,
    linkContext: 'featured-section',
    source: 'featured-section',
  })

  const video = useMemo(() => {
    const content = contentCollection?.items?.[0]
    return {
      title: content?.title ?? 'Trailer',
      url: content?.source ?? '',
    }
  }, [contentCollection?.items])

  const handleLinkClick = () => {
    track('Featured Project Watch Now Link Clicked', { href })
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    track('Featured Project Watch Trailer Clicked', { href })
    setShowModal(true)
  }

  const showLink = Boolean(analyticsLink)
  const showVideo = Boolean(video?.url)

  return (
    <div className="flex w-full flex-col justify-center gap-4 md:flex-row">
      <When condition={showLink}>
        <LinkButton
          href={analyticsLink}
          className="h-12 w-full min-w-[220px] justify-center space-x-2 md:w-fit"
          onClick={handleLinkClick}
          variant="white"
        >
          <PlayIcon size={24} color="current" />
          <TitleXS as={AsDiv}>{t('watchNow', 'Watch Now')}</TitleXS>
        </LinkButton>
      </When>
      <When condition={showVideo}>
        <Button
          className="h-12 w-full min-w-[220px] justify-center space-x-2 md:w-fit"
          variant="white"
          onClick={handleClick}
          outline
        >
          <PlayIcon size={24} color="current" />
          <TitleXS>{t('watchTrailer', 'Watch Trailer')}</TitleXS>
        </Button>
      </When>
      <Modal
        className="z-[99999]"
        panelClassesOverride="inline-block w-3/4 xl:w-full max-w-7xl align-middle shadow-xl rounded-2xl transition-all transform overflow-hidden"
        childWrapperClassName="mt-0"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <EmbeddedVideo className="w-full rounded-2xl" {...video} />
      </Modal>
    </div>
  )
}
