import React, { useState } from 'react'
import { projectTypes } from '@/constants/projectTypes'
import { Menu } from '@/molecules/Menu'
import { EpisodesSelector } from '@/organisms/EpisodesSelector'
import { Project, Season } from '@/services/ProjectsService'
import { FeaturedProject } from '@/types/codegen-contentful'

export interface FeaturedShowCarouselProps {
  featuredProject: FeaturedProject
  project?: Project
  userGroups?: { name: string }[]
}

export const FeaturedProjectCarousel: React.FC<FeaturedShowCarouselProps> = ({
  project,
  userGroups,
  featuredProject,
}) => {
  const [selectedSeason, setSelectedSeason] = useState<Season | undefined>(project?.seasons?.[0])
  const hasRelatedContent = featuredProject?.contentCollection?.items?.length ?? 0 > 0

  return project && hasRelatedContent ? (
    <>
      <div className="relative">
        {project?.projectType !== projectTypes.movie && (
          <Menu
            menuClassName="mb-6"
            buttonClassName="!bg-white !bg-opacity-10"
            options={project?.seasons as Season[]}
            value={selectedSeason || undefined}
            onChange={setSelectedSeason}
          />
        )}
      </div>
      <EpisodesSelector
        display="carousel"
        displayDescription={false}
        projectSlug={project?.slug as string}
        selectedSeason={selectedSeason as Season}
        projectName={project?.name}
        userGroups={userGroups}
        projectType={project?.projectType}
      />
    </>
  ) : null
}
