import React from 'react'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { BackgroundImage } from './FeaturedProjectUtils'

interface FeaturedProjectBackgroundProps {
  backgroundImage: BackgroundImage
}

export const FeaturedProjectBackground: React.FC<FeaturedProjectBackgroundProps> = ({ backgroundImage }) => {
  return (
    <>
      <ResponsiveBackgroundImage {...backgroundImage} />
      {/* gradient for background image */}
      {backgroundImage.gradient && (
        <div className="absolute left-0 top-0 h-full w-full bg-gradient-to-t from-gray-950" />
      )}
    </>
  )
}
