import { FeaturedProject } from '@/types/codegen-contentful'
import { FeaturedProjectBackground } from './FeaturedProjectBackground'
import { FeaturedProjectCallToAction } from './FeaturedProjectCallToAction'
import { FeaturedProjectExperimentWrapper } from './FeaturedProjectExperimentWrapper'
import { assignBackgroundImage } from './FeaturedProjectUtils'

export interface FeaturedProjectProps {
  featuredProject: FeaturedProject
}

export const FeaturedProjectDisplay: React.FC<FeaturedProjectProps> = ({ featuredProject }) => {
  const {
    backgroundImageExperiment,
    ctaPosition,
    desktopBackgroundUrl,
    mobileBackgroundUrl,
    tabletBackgroundUrl,
    ultrawideBackgroundUrl,
  } = featuredProject

  const tablet = tabletBackgroundUrl ?? desktopBackgroundUrl

  const backgrounds = {
    mobile: mobileBackgroundUrl as string,
    tablet: tablet as string,
    desktop: desktopBackgroundUrl as string,
    ultra: ultrawideBackgroundUrl as string,
    gradient: true,
  }

  const backgroundImage = assignBackgroundImage(backgrounds)

  return (
    <div className="relative h-[90vh] bg-transparent lg:min-h-[1000px] shortDesktop:min-h-[800px]">
      {backgroundImageExperiment ? (
        <FeaturedProjectExperimentWrapper featuredProject={featuredProject} />
      ) : (
        <div className="relative h-full w-full">
          <FeaturedProjectBackground backgroundImage={backgroundImage} />
          <FeaturedProjectCallToAction featuredProject={featuredProject} position={ctaPosition ?? 'left'} />
        </div>
      )}
    </div>
  )
}
