export type BackgroundImage = {
  gradient: boolean
  gravity: string
  responsiveAR: {
    sm: string
    md: string
    lg: string
    xl: string
    '2xl': string
  }
  src: string
  srcUrls: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    '2xl': string
  }
}

interface AssignBackgroundImageProps {
  gradient?: boolean
  gravity?: string
  mobile: string
  desktop: string
  tablet: string
  ultra: string
}

export function assignBackgroundImage({
  desktop,
  gradient = true,
  gravity = 'g_north',
  mobile,
  tablet,
  ultra,
}: AssignBackgroundImageProps): BackgroundImage {
  return {
    gradient,
    gravity,
    responsiveAR: {
      sm: '1:1',
      md: '16:9',
      lg: '20:13',
      xl: '20:13',
      '2xl': '24:10',
    },
    src: desktop,
    srcUrls: {
      xs: mobile,
      sm: tablet,
      md: tablet,
      lg: desktop,
      xl: desktop,
      '2xl': ultra,
    },
  }
}
