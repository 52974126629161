import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const PlayOutlineIcon: React.FC<OneColorIconProps> = ({ className, color = 'core-gray-100', size = 12 }) => {
  const hex = getHexColor(color)

  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4511 23.9455L22.6593 16.152C23.1087 15.8508 23.3334 15.4241 23.3334 14.8719C23.3334 14.3197 23.1087 13.893 22.6593 13.5918L10.4511 5.79825C9.9518 5.47195 9.44599 5.45287 8.93369 5.74102C8.4224 6.03017 8.16675 6.47595 8.16675 7.07835V22.6654C8.16675 23.2678 8.4224 23.7131 8.93369 24.0012C9.44599 24.2904 9.9518 24.2718 10.4511 23.9455ZM10.5001 8.59777V21.146L20.3282 14.8719L10.5001 8.59777Z"
        fill={hex}
      />
    </svg>
  )
}
