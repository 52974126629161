import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const ArrowUpRightFromSquare: React.FC<OneColorIconProps> = ({ className, color = 'white', size = 16 }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_492_35570)">
        <path
          d="M7.5 0C7.08516 0 6.75 0.335156 6.75 0.75C6.75 1.16484 7.08516 1.5 7.5 1.5H9.43828L4.72031 6.22031C4.42734 6.51328 4.42734 6.98906 4.72031 7.28203C5.01328 7.575 5.48906 7.575 5.78203 7.28203L10.5 2.56172V4.5C10.5 4.91484 10.8352 5.25 11.25 5.25C11.6648 5.25 12 4.91484 12 4.5V0.75C12 0.335156 11.6648 0 11.25 0H7.5ZM1.875 0.75C0.839062 0.75 0 1.58906 0 2.625V10.125C0 11.1609 0.839062 12 1.875 12H9.375C10.4109 12 11.25 11.1609 11.25 10.125V7.5C11.25 7.08516 10.9148 6.75 10.5 6.75C10.0852 6.75 9.75 7.08516 9.75 7.5V10.125C9.75 10.3313 9.58125 10.5 9.375 10.5H1.875C1.66875 10.5 1.5 10.3313 1.5 10.125V2.625C1.5 2.41875 1.66875 2.25 1.875 2.25H4.5C4.91484 2.25 5.25 1.91484 5.25 1.5C5.25 1.08516 4.91484 0.75 4.5 0.75H1.875Z"
          fill={getHexColor(color)}
        />
      </g>
      <defs>
        <clipPath id="clip0_492_35570">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
