import React from 'react'
import { useExperiment } from '@optimizely/react-sdk'
import { When } from 'react-if'
import { EpisodesHorizontalScroller } from '@/organisms/EpisodesHorizontalScroller'
import { useGuildUser } from '@/services/GuildUserService/GuildUserService'
import { ProjectType, Season } from '@/services/ProjectsService'
import { episodesToMedias } from '@/utils/EpisodeUtil/EpisodeUtil'
import { useLocale } from '@/utils/LocaleUtil'
import { getProjectTheme } from '@/utils/project-themes'
import { hasSeasonEarlyAccess } from '@/utils/users/users'
import { EpisodeGrid } from './EpisodeGrid'

export interface EpisodeSelectorProps {
  projectSlug: string
  projectName?: string
  userGroups?: { name: string }[]
  selectedSeason?: Season
  display?: 'grid' | 'carousel'
  displayDescription?: boolean
  loading?: boolean
  hasError?: boolean
  refetch?: () => void
  projectType?: ProjectType
}

export const EpisodesSelector: React.FC<EpisodeSelectorProps> = ({
  display = 'grid',
  projectSlug,
  selectedSeason,
  loading,
  hasError,
  refetch,
  displayDescription = true,
  projectName,
  userGroups,
  projectType,
}) => {
  const { locale } = useLocale()
  const projectTheme = getProjectTheme(projectSlug)
  const { isGuildMember, guildRoles } = useGuildUser()
  const experiment = useExperiment('guild_membership_tiers_experiment')
  const experimentOn = experiment[0] === 'on'
  const userHasGuildEarlyAccess = experimentOn ? Boolean(guildRoles.hasEarlyAccess) : isGuildMember

  const hasEarlyAccess =
    hasSeasonEarlyAccess(projectTheme.watch?.seasonEarlyAccessGroup, userGroups) || userHasGuildEarlyAccess
  const medias = episodesToMedias(selectedSeason?.episodes || [], {
    hasEarlyAccess,
    displayDescription,
    projectName,
    projectType,
    locale,
  })

  return (
    <>
      <When condition={loading}>
        <div className="h-[2.25rem] w-[6.25rem] animate-pulse rounded bg-black" />
      </When>
      {display === 'grid' ? (
        <EpisodeGrid
          projectSlug={projectSlug}
          medias={medias}
          loading={loading}
          hasError={hasError}
          refetch={refetch}
          userHasEarlyAccess={hasEarlyAccess}
          experimentOn={experimentOn}
        />
      ) : (
        <EpisodesHorizontalScroller medias={medias} />
      )}
    </>
  )
}
