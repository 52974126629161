import React, { useState } from 'react'
import classNames from 'classnames'
import { Button } from '@/atoms/Button'
import { ArrowUpRightFromSquare } from '@/atoms/Icons/ArrowUpRightFromSquare'
import { PlayOutlineIcon } from '@/atoms/Icons/PlayOutlineIcon'
import { CaptionSM, HeadingXL, LabelMD, ParagraphLG } from '@/atoms/Text'
import { BeforeYouLeaveProvider } from '@/contexts/BeforeYouLeaveContext'
import { useRegionContext } from '@/contexts/RegionContext'
import { BeforeYouLeaveModal } from '@/molecules/BeforeYouLeaveModal'
import { AngelFundingLink } from '@/molecules/InvestLink'
import { YoutubeVideoModal } from '@/molecules/YoutubeVideoModal'
import { isHasTypeName } from '@/services/RenderService'
import { InvestmentBanner as InvestmentBannerContent } from '@/types/codegen-contentful'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { ResponsiveBackgroundImage } from '../../atoms/ResponsiveBackgroundImage'

export const isInvestmentBanner = (content: unknown) =>
  isHasTypeName(content) && content.__typename === 'InvestmentBanner'

interface Props extends InvestmentBannerContent {
  className?: string
  hideTrailer?: boolean
}

export const InvestmentBanner: React.FC<Props> = ({
  className,
  hideTrailer,
  ctaText,
  description,
  desktopBackgroundImageCloudinaryPath,
  gravity,
  interstitialButtonText,
  interstitialText,
  mobileBackgroundImageCloudinaryPath,
  offeringUrl,
  tabletBackgroundImageCloudinaryPath,
  title,
  trailerYouTubeId,
  subsidiaryName,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslate('common')
  const { region } = useRegionContext()
  const isUS = region === 'US'
  const leavingMessage =
    interstitialText || t('youAreLeavingToRegA', 'You are now leaving Angel Studios, Inc. to view a Reg A+ offering')
  const leavingContinueLabel = interstitialButtonText || t('continueToOffering', 'Continue to Offering')

  const cloudinaryImgPaths = {
    mobile: String(mobileBackgroundImageCloudinaryPath),
    tablet: String(tabletBackgroundImageCloudinaryPath),
    desktop: String(desktopBackgroundImageCloudinaryPath),
  }

  if (!isUS || !offeringUrl) return null

  return (
    <BeforeYouLeaveProvider>
      <BeforeYouLeaveModal />
      <div className={classNames('mx-4 flex flex-col gap-6 sm:mx-6 md:mx-8', className)}>
        <div className="relative overflow-hidden rounded-[32px]" id="investment-banner">
          <ResponsiveBackgroundImage
            src={cloudinaryImgPaths.desktop}
            srcUrls={{
              xs: cloudinaryImgPaths.mobile,
              sm: cloudinaryImgPaths.tablet,
              md: cloudinaryImgPaths.tablet,
              lg: cloudinaryImgPaths.desktop,
              xl: cloudinaryImgPaths.desktop,
            }}
            gravity={gravity || 'g_face'}
            responsiveAR={{
              xs: '9:16',
              sm: 1.16,
              md: 1.16,
              lg: '16:9',
              xl: '16:9',
            }}
          />
          <div className="relative flex flex-col items-center gap-8 px-4 pb-3 pt-[100%] text-center md:items-start md:px-12 md:pb-12 md:text-left lg:pb-14 lg:pt-[30%] 3xl:pt-[15%]">
            <div className="flex flex-col items-center gap-4 md:max-w-[520px] md:items-start">
              <HeadingXL color="white" weight="bold" className="md:photon-display md:max-w-[430px]">
                {title}
              </HeadingXL>
              <ParagraphLG color="core-gray-200">{description}</ParagraphLG>
            </div>

            <div className="flex flex-col gap-4 md:flex-row">
              <AngelFundingLink
                href={offeringUrl}
                linkContext="Investment Banner"
                leavingMessage={leavingMessage}
                leavingContinueLabel={leavingContinueLabel}
              >
                <Button variant="white" className="mx-auto w-fit px-6 py-[14px] md:mx-0">
                  <LabelMD className="flex flex-row gap-2">
                    {ctaText}
                    <ArrowUpRightFromSquare color="black" size={16} />
                  </LabelMD>
                </Button>
              </AngelFundingLink>
              {!hideTrailer && trailerYouTubeId && (
                <Button
                  variant="plain"
                  onClick={() => setIsModalOpen(true)}
                  className="flex shrink-0 items-center justify-center gap-2 text-white"
                >
                  <PlayOutlineIcon size={28} /> Watch Trailer
                </Button>
              )}
            </div>
          </div>
        </div>
        <CaptionSM
          color="core-gray-400"
          weight="medium"
          className="mx-auto max-w-[1174px] text-pretty 2xl:max-w-[1328px]"
        >
          <Translate
            i18nKey="launchMovieOfferingDisclaimerWithSubsidiaryName"
            t={t}
            values={{ offeringUrl, subsidiaryName }}
          >
            Disclaimer: {{ subsidiaryName }}, a subsidiary of Angel Studios, Inc. is conducting an offering of
            securities under Regulation A. Investing in this offering is high-risk and neither {{ subsidiaryName }}, nor
            its parent company Angel Studios guarantee performance of the investment. You should not invest unless you
            can afford to lose the full amount invested. A description of the terms of the offering and your investment
            (including the timing, manner and amount of any return), can be found in the subscription agreement
            available at{' '}
            <AngelFundingLink
              href={offeringUrl}
              linkContext="Investment Banner"
              className="inline-block !w-fit cursor-pointer underline"
              leavingMessage={leavingMessage}
              leavingContinueLabel={leavingContinueLabel}
            >
              {/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore */}
              {{ offeringUrl }}
            </AngelFundingLink>
            . You should not invest until you have reviewed and understood the offering documents, including the risk
            factors contained in its offering circular.
          </Translate>
        </CaptionSM>
      </div>
      {trailerYouTubeId && (
        <YoutubeVideoModal
          youTubeUrl={`https://www.youtube.com/embed/${trailerYouTubeId}`}
          handleClose={() => setIsModalOpen(false)}
          showModal={isModalOpen}
        />
      )}
    </BeforeYouLeaveProvider>
  )
}
