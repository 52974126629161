import React from 'react'
import { Button } from '@/atoms/Button'
import { RetryIcon } from '@/atoms/Icons/RetryIcon'

interface RetryFallbackProps {
  className?: string
  message: string
  ctaAction: () => void
  ctaText: string
}

export const RetryFallback: React.FC<RetryFallbackProps> = ({ className = '', message, ctaAction, ctaText }) => {
  return (
    <div className={className}>
      <div className="mb-4">{message}</div>
      <Button variant="white" outline onClick={ctaAction} className="mx-auto flex stroke-white hover:stroke-gray-950">
        <div>
          <RetryIcon className="mr-2" color="none" />
        </div>
        <div>{ctaText}</div>
      </Button>
    </div>
  )
}
