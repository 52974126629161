import { gql as contentfulGQL } from '@apollo/client'
import { ContentfulClientOptions, getContentfulClient } from '@/services/ApolloClient'
import { FeaturedProject } from '@/types/codegen-contentful'

export const GET_FEATURED_PROJECT_BY_ID_QUERY = contentfulGQL/* GraphQL */ `
  query getFeaturedProjectById($id: String!, $locale: String, $preview: Boolean, $region: String) {
    featuredProjectCollection(
      locale: $locale
      preview: $preview
      limit: 1
      where: { sys: { id: $id }, OR: [{ region_exists: false }, { region_contains_all: [$region] }] }
    ) {
      items {
        backgroundImageExperiment
        campaign
        contentCollection {
          items {
            cloudinaryImagePath
            contentType
            group
            guid
            logoOverlayClassName
            logoOverlayCloudinaryPath
            project
            source
            title
          }
        }
        ctaPosition
        guildMemberHref
        heading
        guildHeading
        href
        logoHeight
        logoUrl
        logoWidth
        mobileBackgroundUrl
        tabletBackgroundUrl
        desktopBackgroundUrl
        ultrawideBackgroundUrl
        pillBackgroundColor
        pillLabel
        projectSlug
        showGuildCta
        showTicketsCta
        showWatchCta
        sys {
          id
        }
        status
        subHeading
        textColor
        sneakPeekUrl
        projectTheme {
          sneakPeekDuration
        }
      }
    }
  }
`

export const getFeaturedProjectsQuery = contentfulGQL/* GraphQL */ `
  query getFeaturedProjects($locale: String, $preview: Boolean, $region: String) {
    featuredProjectCollection(
      limit: 3
      locale: $locale
      order: sys_publishedAt_DESC
      preview: $preview
      where: { page: "watch-landing-page", OR: [{ region_exists: false }, { region_contains_all: [$region] }] }
    ) {
      items {
        sys {
          id
        }
      }
    }
  }
`
export const GET_FEATURED_PROJECT_BY_PROJECT_SLUG_AND_PAGE_QUERY = contentfulGQL/* GraphQL */ `
  query getFeaturedProjectsByProjectSlugAndPage(
    $projectSlug: String
    $page: String
    $locale: String
    $preview: Boolean
    $region: String
  ) {
    featuredProjectCollection(
      limit: 1
      locale: $locale
      order: sys_publishedAt_DESC
      preview: $preview
      where: {
        page: $page
        projectSlug: $projectSlug
        OR: [{ region_exists: false }, { region_contains_all: [$region] }]
      }
    ) {
      items {
        backgroundImageExperiment
        campaign
        contentCollection {
          items {
            cloudinaryImagePath
            contentType
            group
            guid
            logoOverlayClassName
            logoOverlayCloudinaryPath
            project
            source
            title
          }
        }
        ctaPosition
        desktopBackgroundUrl
        guildMemberHref
        heading
        guildHeading
        href
        logoHeight
        logoUrl
        logoWidth
        mobileBackgroundUrl
        pillBackgroundColor
        pillLabel
        projectSlug
        showGuildCta
        showTicketsCta
        showWatchCta
        sys {
          id
        }
        status
        subHeading
        textColor
        ultrawideBackgroundUrl
      }
    }
  }
`

export async function getFeaturedProjects(opts: ContentfulClientOptions): Promise<FeaturedProject[]> {
  const { data } = await getContentfulClient(opts).query({
    query: getFeaturedProjectsQuery,
    variables: { preview: opts?.preview, region: opts?.region },
  })

  return data?.featuredProjectCollection?.items
}

export async function getFeaturedProjectByProjectSlugAndPage({
  projectSlug,
  page,
  ...opts
}: { projectSlug: string; page: string } & ContentfulClientOptions): Promise<FeaturedProject | null> {
  const { data } = await getContentfulClient(opts).query({
    query: GET_FEATURED_PROJECT_BY_PROJECT_SLUG_AND_PAGE_QUERY,
    variables: { preview: opts?.preview, region: opts?.region, projectSlug, page },
  })

  return data?.featuredProjectCollection?.items?.[0] || null
}
