import { GuildIcon } from '@/atoms/Icons/GuildIcon'
import { TitleMD } from '@/atoms/Text'
import { useTranslate } from '@/utils/translate/translate-client'

export const FeaturedProjectGuildLabel = () => {
  const { t } = useTranslate('common')

  return (
    <div className="mx-auto flex flex-row">
      <GuildIcon size={32} color1="white" />
      <TitleMD className="pl-1 text-sm uppercase md:text-xl">{t('guildEarlyAccess', 'Guild Early Access')}</TitleMD>
    </div>
  )
}
