import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const RetryIcon: React.FC<OneColorIconProps> = ({ size = 24, color = 'black', className }) => {
  return (
    <svg
      data-testid="retry-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 256 256"
      className={className}
    >
      <polyline
        stroke={getHexColor(color)}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        points="176.2 99.7 224.2 99.7 224.2 51.7"
      />
      <path
        stroke={getHexColor(color)}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="16"
        d="M190.2,190.2a88,88,0,1,1,0-124.4l34,33.9"
      />
    </svg>
  )
}
