import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import LazyHydrate from 'react-lazy-hydration'
import { useRegionContext } from '@/contexts/RegionContext'
import { getContentfulClient } from '@/services/ApolloClient'
import { GET_FEATURED_PROJECT_BY_ID_QUERY } from '@/services/CmsService/FeaturedProject'
import { FeaturedProject, GetFeaturedProjectByIdQuery } from '@/types/codegen-contentful'
import { useLocale } from '@/utils/LocaleUtil'
import { FeaturedProjectDisplay } from './FeaturedProjectDisplay'
import { FeaturedProjectPlaceholder } from './FeaturedProjectPlaceholder'

export interface FeaturedProjectSectionProps {
  featuredProjectId: string
  preview?: boolean
  testId?: string
}
export const FeaturedProjectSection: React.FC<FeaturedProjectSectionProps> = ({
  preview = false,
  featuredProjectId,
}) => {
  const { locale } = useLocale()
  const { region } = useRegionContext()

  const client = getContentfulClient({ locale, preview, region })

  const [getFeaturedProjectById, { data, loading }] = useLazyQuery<GetFeaturedProjectByIdQuery>(
    GET_FEATURED_PROJECT_BY_ID_QUERY,
    { client },
  )

  useEffect(() => {
    getFeaturedProjectById({ variables: { id: featuredProjectId, region, locale, preview } })
  }, [featuredProjectId, getFeaturedProjectById, locale, preview, region])

  const featuredProject = data?.featuredProjectCollection?.items?.[0]

  return (
    <section id="featured" data-testid={featuredProjectId}>
      <LazyHydrate whenVisible>
        <>
          {loading && <FeaturedProjectPlaceholder />}

          {featuredProject && <FeaturedProjectDisplay featuredProject={featuredProject as FeaturedProject} />}
        </>
      </LazyHydrate>
    </section>
  )
}
