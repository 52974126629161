import React, { ReactNode } from 'react'
import { Menu as HeadlessMenu } from '@headlessui/react'
import classNames from 'classnames'
import { CaretDownIcon } from '@/atoms/Icons/CaretDownIcon'

export interface DropdownOption {
  name: string
  displayElement?: ReactNode
}

interface DropdownProps<T extends DropdownOption> {
  options: T[]
  value?: T
  onChange: (option: T) => void
  buttonClassName?: string
  iconClassName?: string
  iconSize?: number
  menuClassName?: string
  itemContainerClassName?: string
}

export function Menu<T extends DropdownOption>({
  options = [],
  value,
  onChange,
  menuClassName,
  buttonClassName,
  iconClassName,
  itemContainerClassName,
  iconSize = 20,
}: DropdownProps<T>) {
  return (
    <HeadlessMenu
      aria-label={options[0]?.name.includes('Season') ? 'season-selector' : 'dropdown'}
      as="div"
      className={classNames('inline-block relative active:border-accent w-full md:w-fit', menuClassName)}
    >
      {({ open }) => (
        <>
          <HeadlessMenu.Button
            className={classNames(
              'w-full justify-between border border-gray-400 flex text-sm items-center relative bg-transparent text-white py-4 px-8 text-left rounded-lg shadow-md sm:text-sm focus:outline-none focus-visible:ring-2 focus-visible:ring-accent focus-visible:ring-opacity/75 mr-2',
              buttonClassName,
            )}
          >
            <div className="whitespace-nowrap" aria-label="current-dropdown-value">
              {value?.displayElement ?? value?.name}
            </div>
            <CaretDownIcon
              size={iconSize}
              className={classNames('transition md:ml-16', open ? 'rotate-180' : 'rotate-0', iconClassName)}
            />
          </HeadlessMenu.Button>
          <HeadlessMenu.Items
            className={classNames(
              'ring-opacity/5 absolute left-0 z-10 mt-1 min-w-full overflow-auto rounded-md bg-black py-1 shadow-lg ring-1 ring-black focus:outline-none sm:text-sm',
              itemContainerClassName,
            )}
          >
            {options?.map((option) => {
              return (
                <HeadlessMenu.Item key={option.name}>
                  {({ active }) => (
                    <div
                      aria-label="dropdown-option"
                      onClick={() => onChange(option)}
                      className={`relative cursor-pointer select-none px-4 py-2 ${
                        active ? 'text-accent bg-core-gray-950' : 'text-white'
                      }`}
                    >
                      {option?.displayElement ?? option.name}
                    </div>
                  )}
                </HeadlessMenu.Item>
              )
            })}
          </HeadlessMenu.Items>
        </>
      )}
    </HeadlessMenu>
  )
}
