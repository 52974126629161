import { CaptionLG, CaptionSM } from '@/atoms/Text'
import { FeaturedProject } from '@/types/codegen-contentful'
import { Translate, useTranslate } from '@/utils/translate/translate-client'

interface FeaturedProjectLabelProps {
  featuredProject: FeaturedProject
}

export const FeaturedProjectLabel: React.FC<FeaturedProjectLabelProps> = ({ featuredProject }) => {
  const { pillBackgroundColor, pillLabel, showTicketsCta } = featuredProject
  const { t } = useTranslate('common')

  return showTicketsCta ? (
    <CaptionLG className="mx-auto text-center uppercase" weight="semibold">
      <Translate t={t} i18nKey="inTheaters">
        In Theaters
      </Translate>
      <span style={{ color: pillBackgroundColor ?? 'inherit' }}>{` ${pillLabel}`}</span>
    </CaptionLG>
  ) : (
    <CaptionSM
      style={pillBackgroundColor ? { backgroundColor: pillBackgroundColor } : {}}
      className="mt-4 w-fit rounded-full px-2 py-1"
    >
      {pillLabel}
    </CaptionSM>
  )
}
