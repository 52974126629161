import React from 'react'
import { getHexColor, OneColorIconProps } from '../utils'

export const CircleCloseIcon: React.FC<OneColorIconProps> = ({ color = 'gray-600', className, size }) => {
  const hex = getHexColor(color)
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0003 27.3334C12.1559 27.3334 10.4225 26.9831 8.80033 26.2827C7.1781 25.5831 5.76699 24.6334 4.56699 23.4334C3.36699 22.2334 2.41721 20.8222 1.71766 19.2C1.01721 17.5778 0.666992 15.8445 0.666992 14C0.666992 12.1556 1.01721 10.4222 1.71766 8.80002C2.41721 7.1778 3.36699 5.76669 4.56699 4.56669C5.76699 3.36669 7.1781 2.41646 8.80033 1.71602C10.4225 1.01646 12.1559 0.666687 14.0003 0.666687C15.8448 0.666687 17.5781 1.01646 19.2003 1.71602C20.8225 2.41646 22.2337 3.36669 23.4337 4.56669C24.6337 5.76669 25.5834 7.1778 26.283 8.80002C26.9834 10.4222 27.3337 12.1556 27.3337 14C27.3337 15.8445 26.9834 17.5778 26.283 19.2C25.5834 20.8222 24.6337 22.2334 23.4337 23.4334C22.2337 24.6334 20.8225 25.5831 19.2003 26.2827C17.5781 26.9831 15.8448 27.3334 14.0003 27.3334ZM9.20033 20.1C8.82255 20.1 8.51144 19.9778 8.26699 19.7334C8.02255 19.4889 7.89499 19.1831 7.88433 18.816C7.87277 18.4498 7.98922 18.1445 8.23366 17.9L12.1337 14L8.26699 10.1334C8.02255 9.88891 7.90033 9.5778 7.90033 9.20002C7.90033 8.82224 8.02255 8.51113 8.26699 8.26669C8.51144 8.02224 8.81722 7.89424 9.18433 7.88269C9.55055 7.87202 9.85588 7.98891 10.1003 8.23335L14.0003 12.1334L17.867 8.26669C18.1114 8.02224 18.4226 7.90002 18.8003 7.90002C19.1781 7.90002 19.4892 8.02224 19.7337 8.26669C19.9781 8.51113 20.1057 8.81647 20.1163 9.18269C20.1279 9.5498 20.0114 9.85558 19.767 10.1L15.867 14L19.7337 17.8667C19.9781 18.1111 20.1003 18.4222 20.1003 18.8C20.1003 19.1778 19.9781 19.4889 19.7337 19.7334C19.4892 19.9778 19.1839 20.1054 18.8177 20.116C18.4506 20.1276 18.1448 20.0111 17.9003 19.7667L14.0003 15.8667L10.1337 19.7334C9.88922 19.9778 9.5781 20.1 9.20033 20.1Z"
        fill={hex}
      />
    </svg>
  )
}
