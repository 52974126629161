import React from 'react'
import { Switch, Case, Default } from 'react-if'
import { MediaCard, PlaceholderMediaCard } from '@/molecules/MediaCard'
import { RetryFallback } from '@/molecules/RetryFallback'
import { Media } from '@/utils/EpisodeUtil'
import { createPlaceholderArray } from '@/utils/PlaceholderArrays'
import { useTranslate } from '@/utils/translate/translate-client'

const PLACEHOLDER_TILES = createPlaceholderArray(8)
const PLACEHOLDER_CHILDREN = PLACEHOLDER_TILES.map((tile) => <PlaceholderMediaCard key={tile} />)

interface EpisodeGridProps {
  projectSlug: string
  loading?: boolean
  refetch?: () => void
  hasError?: boolean
  medias?: Media[]
  experimentOn?: boolean
  userHasEarlyAccess?: boolean
}

export const EpisodeGrid: React.FC<EpisodeGridProps> = ({
  projectSlug,
  medias,
  loading,
  hasError,
  refetch,
  experimentOn,
  userHasEarlyAccess,
}) => {
  const { t } = useTranslate('watch')

  return (
    <div className="grid grid-cols-1 gap-4 pt-3 sm:grid-cols-2 md:grid-cols-3 md:pt-4 lg:grid-cols-4">
      <Switch>
        <Case condition={loading}>{PLACEHOLDER_CHILDREN}</Case>
        <Case condition={hasError}>
          <RetryFallback
            className="py-20 text-center sm:col-span-2 md:col-span-3 lg:col-span-2 lg:col-start-2"
            message={t('problemLoadingEpisodes', 'Oops! There was a problem loading episodes.')}
            ctaAction={() => refetch?.()}
            ctaText={t('retry', 'Retry')}
          />
        </Case>
        <Case condition={medias?.length && medias.length > 0}>
          <EpisodeCards
            medias={medias}
            projectSlug={projectSlug}
            userHasEarlyAccess={userHasEarlyAccess}
            experimentOn={experimentOn}
          />
        </Case>
        <Default>
          <div className="py-20 text-center sm:col-span-2 md:col-span-3 lg:col-span-2 lg:col-start-2">
            {t('seasonHasNoEpisodes', 'This season has no episodes.')}
          </div>
        </Default>
      </Switch>
    </div>
  )
}

interface EpisodeCardsProps {
  projectSlug: string
  medias?: Media[]
  experimentOn?: boolean
  userHasEarlyAccess?: boolean
}

const EpisodeCards: React.FC<EpisodeCardsProps> = ({ medias, projectSlug, experimentOn, userHasEarlyAccess }) => {
  return (
    <>
      {medias?.map((media) => {
        return (
          <MediaCard
            {...media}
            key={media.guid}
            projectSlug={projectSlug}
            userHasEarlyAccess={userHasEarlyAccess}
            experimentOn={experimentOn}
          />
        )
      })}
    </>
  )
}
